import { Modal } from 'bootstrap';
import { FlashMessage } from '@epigenesys/epi-js';

document.addEventListener('DOMContentLoaded', () => {
  document.body.addEventListener('shown.bs.modal', (event) => {
    if (event.target.id === 'modalWindow') {
      const autofocusElement = event.target.querySelector('[autofocus]');
      if (autofocusElement) {
        event.target.querySelector('[autofocus]').focus();
      }
    }
  });

  document.body.addEventListener('ajax:success', (response) => {
    const path = response.composedPath();
    if(path.find((element) => element.id === 'modalWindow')) {

      const [{ content, targetElement, action = 'update' }, _status, _xhr] = response.detail;
      const element = document.querySelector(targetElement);

      if (content) {
        const template = document.createElement('template');
        template.innerHTML = content.trim();

        if (action === 'update') {
          element.replaceWith(template.content.firstChild);
        } else if (action === 'create') {
          if(element !== null) { //fix for issue where element is null and throws error
            const nodesToAdd = template.content.childNodes
            nodesToAdd.forEach((child) => element.prepend(child))
          }
        }

        Modal.getOrCreateInstance(document.querySelector('#modalWindow')).hide();
      }
      
      if(action === 'reload') {
        window.location.reload()
      }
    }
  });

  document.body.addEventListener('ajax:error', (response) => {
    const path = response.composedPath();

    if (path.find((element) => element.id === 'modalWindow')) {
      const [{ content }, _status, xhr] = response.detail;
      const modalWindow = document.querySelector('#modalWindow');
      if (content) {
        if (xhr.status === 422) {
          modalWindow.innerHTML = content;
          modalWindow.dispatchEvent(new Event('show.bs.modal', { bubbles: true }));
          modalWindow.dispatchEvent(new Event('shown.bs.modal', { bubbles: true }));
        } else {
          Modal.getOrCreateInstance(modalWindow).hide();
          FlashMessage.addMessage('Unable to save the record, please try again', { type: 'alert-danger' });
        }
      }
    }
  });
});
