import { getCsrfToken } from './utils';
import { FlashMessage } from '@epigenesys/epi-js';

document.addEventListener('click', async (event) => {
  const path = event.composedPath();
  const saveProgressButton = path.find((element) => element.matches && element.matches('[data-save-progress]'));

  if (saveProgressButton) {
    event.preventDefault();
    event.stopPropagation();

    const form = saveProgressButton.closest('form');

    const response = await fetch(`${form.getAttribute('action')}.json`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'x-csrf-token': getCsrfToken()
      },
      body: new FormData(form)
    });
    const { newContent } = await response.json();

    if (newContent) {
      const template = document.createElement('template');
      template.innerHTML = newContent.trim();
      form.replaceWith(template.content.firstChild);
      FlashMessage.addMessage('Progress has been saved. Other staff members will not be able to see this record until you have submitted it.', { type: 'alert-primary' });
    }
  }
});