import Rails from "@rails/ujs";
import "bootstrap";

import 'scripts/modal_form';
import 'scripts/dropdown_check';
import 'scripts/autoresize';
import 'scripts/save_progress';

import { AjaxModal, AutoUpdate, ConfirmModal, TableReflow, VisibilityMap } from '@epigenesys/epi-js';

TableReflow.start();
AjaxModal.start();
AutoUpdate.start();

VisibilityMap.start();

ConfirmModal.options.cancelButtonClass = 'btn btn-default';

Rails.confirm = function (message, element) {
  ConfirmModal.options.cancelButtonText = element.dataset.cancelText ? element.dataset.cancelText : 'No'
  ConfirmModal.options.commitButtonText = element.dataset.confirmText ? element.dataset.confirmText : 'Yes'
  return ConfirmModal.confirm(message, element);
}

Rails.start();