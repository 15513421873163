export const getCsrfToken = () => {
  const metaCsrf = document.querySelector("meta[name='csrf-token']");
  return metaCsrf ? metaCsrf.getAttribute('content') : 'testenv';
}

export const toggleVisibility = (element, show) => {
  if(!element) { return }
    if (show) {
      if(element.classList.contains('d-none')) {
        element.classList.remove('d-none');
      }
      element.style.removeProperty('display');
    } else {
      element.style.display = 'none'
    }
  
    element.toggleAttribute('data-visible', show);
}