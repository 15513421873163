import { ElementMap } from '@epigenesys/epi-js';

class DropDownChecks {
  constructor(element) {
    this.element = element;
    this.toggleElement = element.querySelector('[data-bs-toggle="dropdown"]');
    this.totalOptionsCount = element.querySelectorAll('input[type="checkbox"]').length;
    this.showAllLabel = element.dataset.showAllLabel;
    this.customNoneLabel = element.dataset.customNoneLabel;
    this.showCheckedItemsCount = parseInt(element.dataset.showCheckedItemsCount);
  }

  static start() {
    document.addEventListener('DOMContentLoaded', () => {
      document.querySelectorAll('.dropdown-checks').forEach((element) => {
        ElementMap.getOrCreateInstance(this, element).updateToggleText();
      });

      document.body.addEventListener('show.bs.modal', (event) => {
        if (event.target.id === 'modalWindow') {
          event.target.querySelectorAll('.dropdown-checks').forEach((element) => {
            ElementMap.getOrCreateInstance(this, element).updateToggleText();
          });
        }
      });
    });

    document.addEventListener('change', (event) => {
      const { target } = event;
      if (target && target.matches('.dropdown-check-input')) {
        ElementMap.getOrCreateInstance(this, target.closest('.dropdown-checks')).updateToggleText();
      }
    });
  }

  updateToggleText() {
    const { allCheckedLabels, totalOptionsCount, showAllLabel, showCheckedItemsCount, toggleElement, customNoneLabel } = this;

    let text = allCheckedLabels.join(', ');
    if (showAllLabel && allCheckedLabels.length === totalOptionsCount) {
      text = 'All';
    } else if (allCheckedLabels.length === 0) {
      text = customNoneLabel || 'None';
    } else if (showCheckedItemsCount > 0 && allCheckedLabels.length > showCheckedItemsCount) {
      text = `${allCheckedLabels.slice(0, showCheckedItemsCount).join(', ')} & ${allCheckedLabels.length - showCheckedItemsCount} more`;
    }

    toggleElement.innerHTML = text;
  }

  get allCheckedLabels() {
    return Array.from(this.element.querySelectorAll('input:checked')).map((item) => `<span>${item.parentNode.innerText.trim()}</span>`);
  }
}

DropDownChecks.start();